<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.href.label')"
                        :label-for="'href.' + language.code"
                      >
                        <b-form-input
                          :id="'href.' + language.code"
                          v-model="item.href[language.code]"
                          :state="errors && errors['href.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['href.' + language.code]">
                          {{ errors['href.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('administration.products.tabs.data.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.parent_id.label')"
                    label-for="parent_id"
                    :state="errors && errors.parent_id ? false : null"
                  >
                    <treeselect
                      id="parent_id"
                      v-model="item.parent_id"
                      :multiple="false"
                      :options="items"
                      :normalizer="normalizer"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.parent_id">
                      {{ errors.parent_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.product.label')"
                    label-for="product_id"
                    :state="errors && errors.product_id ? false : null"
                  >
                    <v-select
                      id="product_id"
                      v-model="item.product_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productOptions"
                      :reduce="val => val.value"
                      input-id="product_id"
                      :searchable="true"
                      @search="searchProduct"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.product_id">
                      {{ errors.product_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.blank.label')"
                    label-for="blank"
                  >
                    <b-form-checkbox
                      id="blank"
                      v-model="item.blank"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.blank">
                      {{ errors.blank[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="$t('form.avatar.label')">
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="getFlexImgUrl(item.avatar)"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="avatar"
                          type="navigation_item_avatar"
                          :multiple="false"
                          @fm-selected="selectedAvatar"
                        />

                        <b-row
                          v-if="errors && errors.avatar"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.avatar[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['avatar.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['avatar.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="$t('form.icon.label')">
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="getFlexImgUrl(item.icon)"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.icon"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.icon = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="icon"
                          type="navigation_item_icon"
                          :multiple="false"
                          @fm-selected="selectedIcon"
                        />

                        <b-row
                          v-if="errors && errors.icon"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.icon[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['icon.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['icon.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="$t('form.icon_mobile.label')">
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="getFlexImgUrl(item.icon_mobile)"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.icon_mobile"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.icon_mobile = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="icon_mobile"
                          type="navigation_item_icon_mobile"
                          :multiple="false"
                          @fm-selected="selectedIconMobile"
                        />

                        <b-row
                          v-if="errors && errors.icon_mobile"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.icon_mobile[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['icon_mobile.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['icon_mobile.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'navigations-update', params: { id: $route.params.navigation_id } }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import EditorMixin from '@/mixins/EditorMixin'

import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormInvalidFeedback,
  BImg, BMedia, BMediaBody, BMediaAside, BLink, BFormFile, BFormTextarea, BOverlay,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CategoryMixin from '@/mixins/CategoryMixin'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'
import InitFileManager from '@/components/InitFileManager'

Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    vSelect,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BImg,
    BFormFile,
    BFormTextarea,
    quillEditor,
    Treeselect,
    BOverlay,
    InitFileManager,
  },
  mixins: [CategoryMixin, SweetAlertMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      items: [],
      productOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/administration/navigation_items', {
      params: {
        navigation_id: this.$route.params.navigation_id,
      },
    })
      .then(response => {
        this.items = response.data
      })

    await this.$http.get(`/api/administration/navigation_items/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)

        if (!_.isNull(data.product) && !_.find(this.productOptions, { value: data.product.id })) {
          this.productOptions.push({
            value: data.product.id,
            label: data.product.title,
          })
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'navigations-update', params: { id: this.$route.params.navigation_id } })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    getFlexImgUrl(avatar) {
      return avatar?.url || ''
    },
    selectedAvatar(data) {
      this.item.avatar = data
    },
    selectedIcon(data) {
      this.item.icon = data
    },
    selectedIconMobile(data) {
      this.item.icon_mobile = data
    },
    async searchProduct(query) {
      await this.$http.get('/api/administration/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        href: this.item.href,
        parent_id: this.item.parent_id,
        product_id: this.item.product_id,
        avatar: this.item.avatar,
        icon: this.item.icon,
        icon_mobile: this.item.icon_mobile,
        blank: this.item.blank,
        navigation_id: this.$route.params.navigation_id,
      }

      this.$http.put(`/api/administration/navigation_items/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'navigations-update', params: { id: this.$route.params.navigation_id } })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    transformData(item) {
      const data = {
        id: item.id,
        avatar: this.$options.filters.singleMedia(item.avatar),
        icon: this.$options.filters.singleMedia(item.icon),
        icon_mobile: this.$options.filters.singleMedia(item.iconMobile),
        parent_id: item.parent_id,
        product_id: item.product_id,
        blank: item.blank,
        title: {},
        href: {},
      }

      if (!_.isNull(item.product)) {
        data.product_id = item.product.id
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.href[language.code] = translation ? translation.href : null
      })

      return data
    },
  },
}
</script>
